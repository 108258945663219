@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Manrope:wght@400;500;600;700&display=swap");
body {
  font-family: Helvetica-FF,Arial,Tahoma,sans-serif!important;
  background-color:#fff!important
}

option {
  color: black;
}
table {
  border-collapse: separate!important;
  border-spacing: 0 0.75rem;
}

.chakra-breadcrumb__list {
  padding: 0!important;
}

.sticky {
  position: fixed!important;
  top: 100px!important;
  z-index: 1;
  width: 36%;
  background-color: rgba(244, 247, 254, 0.2);
  backdrop-filter: blur(20px);
}

table#tableMatching {
  width: 100%;
  border-collapse: collapse;
}
table#tableMatching>th, table#tableMatching>td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
table#tableMatching>th {
  background-color: #4CAF50;
  color: white;
}

@media all and (max-width: 767px) {
  /* CSS rules here for screens lower than 750px */
    .sticky {
      position: fixed!important;
      top: 100px!important;
      z-index: 1;
      width: 90%;
      background-color: rgba(244, 247, 254, 0.2);
      backdrop-filter: blur(20px);
    }  
    .sticky-guess {
      position: fixed!important;
      top: 50px!important;
      z-index: 1;
      width: 90%;
      background-color: rgba(244, 247, 254, 0.2);
      backdrop-filter: blur(20px);
    }  
  }

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: .5;
  }
  100% {
    opacity: 0;
  }
}